import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { graphql, PageProps } from "gatsby";
import StandardPageContainer from "../components/reviewskew/standardpage/StandardPageContainer";
import ProductMiniSummary from "../components/reviewskew/product/ProductMiniSummary";
import RecentNavControls from "../components/reviewskew/product/RecentNavControls";


const RecentProductReviewsTemplate:FC<PageProps<Queries.RecentProductReviewsQuery>> = ({ data, pageContext }) => {
  const products = data.allContentfulProductReviewSummary.nodes;

  const { currentPage, numPages } = pageContext as any
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? './' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const recentControls = <RecentNavControls currentPage={currentPage} numPages={numPages} />

  return (
    <StandardPageContainer title="Recent Product Reviews">
      <Typography variant="h3">Recent Product Review Summaries</Typography>

      <Grid container justifyContent="space-around" margin={4}>
        {products.map( (product: any) => {
          return <ProductMiniSummary product={product} />
        })}
      </Grid>
      
      <Box>
        {recentControls}
      </Box>

    </StandardPageContainer>
  );
};

export default RecentProductReviewsTemplate;

export const pageQuery = graphql`
query RecentProductReviewsTemplated($skip: Int!, $limit: Int!) {
  allContentfulProductReviewSummary(
    sort: { order: DESC, fields: [updatedAt] }
    limit: $limit
    skip: $skip
  ) {
    nodes {
      productName
      slug
      opinionJson {
        result_text
      }
      updatedAt
    }
  }
  }
`;
