import React, { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { Button, Link } from "gatsby-material-ui-components";


type RecentNavControlsProps = {
  currentPage: number;
  numPages: number;
}

const RecentNavControls:FC<RecentNavControlsProps> = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? './' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Typography variant="h6" component="div">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        <Button variant="contained" href={prevPage} disabled={isFirst}>«</Button>
        <Box>Page {currentPage} of {numPages}</Box>
        <Button variant="contained" href={nextPage} disabled={isLast}>»</Button>
      </Stack>
    </Typography>
  );
};

export default RecentNavControls;
